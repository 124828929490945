<template>
  <el-dialog
    v-model="dialogVisible"
    :title="dialogVisibletitle"
    :close-on-click-modal="false"
    width="900px"
  >
    <!-- {{state.treedata}} -->
    <el-tree
      ref="treeRef"
      :data="state.treedata"
      :default-checked-keys="state.checkedKeys"
      show-checkbox
      default-expand-all
      node-key="id"
      highlight-current
      props="{
        children: 'children',
        label: 'label',
      }"
    />
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton
          class="searcML"
          width="180"
          height="40"
          title="关闭"
          CSStype="3"
          @buttonclick="dialogVisible = false"
        ></oabutton>
        <oabutton
          class="searcML"
          width="180"
          height="40"
          title="保存"
          CSStype="2"
          @buttonclick="saveAuthority()"
          v-model:loading="dialogloading"
        ></oabutton>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits, onMounted } from "vue";
import { httpToken } from "@/utils/request";
import {getmenuData} from "@/utils/server/selectdata";
import qs from "qs";
const dialogloading = ref(false); // 弹框按钮loading 显示
const dialogVisible = ref(false); //弹框显示
const dialogVisibletitle = ref(false); //弹框标题
const treeRef = ref(); //树型ref
const emit = defineEmits(["Emit"]);
const id = ref(0); //操作的id
const state = reactive({
  treedata: [], //树型数据
  checkedKeys:[],//默认生成数据
});
//设置权限
const setAuthority = (row) => {
  state.checkedKeys=[]
  id.value = row.roleId;
  dialogloading.value = false;
  // 返回当前用户的树形菜单集合
  httpToken({
    method: "post",
    url: "/admin/sys-menu/tree/" + row.roleId,
  }).then((res) => {
    // console.log('返回当前用户的树形菜单集合',res)
    dialogVisibletitle.value = "分配权限";
    dialogVisible.value = true;
    nextTick(() => {
      unref(treeRef).setCheckedKeys([], false)
      // console.log('获取所有的太监节点',resolveAllEunuchNodeId(state.treedata, res.data, []))
      state.checkedKeys = resolveAllEunuchNodeId(state.treedata, res.data, [])
    });
  });
};
//保存权限
const saveAuthority = () => {
  dialogloading.value=true
  httpToken({
    method: "post",
    url: "/admin/sys-role/menu",
    data:{
        roleId:id.value,
        menuIds:unref(treeRef).getCheckedKeys().join(',').concat(',').concat(unref(treeRef).getHalfCheckedKeys().join(','))
    }
  }).then((res) => {
    dialogVisible.value = false;
    dialogloading.value=false
    // console.log("保存权限", res);
  }).catch(()=>{
    dialogloading.value=false
  })
};
// 解析出所有的太监节点id
const resolveAllEunuchNodeId=(json, idArr, temp)=>{
  for (let i = 0; i < json.length; i++) {
    const item = json[i]
    // 存在子节点，递归遍历;不存在子节点，将json的id添加到临时数组中
    if (item.children && item.children.length !== 0) {
      resolveAllEunuchNodeId(item.children, idArr, temp)
    } else {
      temp.push(idArr.filter(id => id === item.id))
    }
  }
  return temp
}
onMounted(() => {
  getmenuData().then((res) => {
    // console.log("获取所有权限", res);
    state.treedata = res;
  });
});
defineExpose({
  setAuthority,
});
</script>